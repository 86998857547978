@font-face {
  font-family: 'Questrial';
  src: url(./assets/fonts/Questrial/font.woff2) format('woff2'), url(./assets/fonts/Questrial/font.woff) format('woff');
}
@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-Light/font.woff2) format('woff2'),
    url(./assets/fonts/Gilroy-Light/font.woff) format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-ExtraBold/font.woff2) format('woff2'),
    url(./assets/fonts/Gilroy-ExtraBold/font.woff) format('woff');
  font-weight: 800;
}
@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-Bold/font.woff2) format('woff2'),
    url(./assets/fonts/Gilroy-Bold/font.woff) format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Gilroy';
  src: url(./assets/fonts/Gilroy-SemiBold/font.woff2) format('woff2'),
    url(./assets/fonts/Gilroy-SemiBold/font.woff) format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto/font.woff2) format('woff2'), url(./assets/fonts/Roboto/font.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url(./assets/fonts/Roboto-Bold/font.woff2) format('woff2'),
    url(./assets/fonts/Roboto-Bold/font.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'FontAwesome';
  src: url(./assets/fonts/FontAwesome/font.woff2) format('woff2'),
    url(./assets/fonts/FontAwesome/font.woff) format('woff');
  font-weight: 500;
}

body {
  font-family: Gilroy, sans-serif;
}

.react-date-picker__wrapper {
  border: none !important;
}
